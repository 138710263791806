<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.subtitle
  font-weight: bold
  font-size: 1rem
  color: #777
.ghost
  opacity: 0.5
  // background: #c8ebfb
  // background-color: #fff !important
.dragging
  .flip-list-move
    transition: transform 0.5s
  .btn
    cursor: ns-resize
    background-color: #fff !important

.table-preview
  .sticky-header
    position: sticky
    top: -1px
    background-color: #f0f0f0
    z-index: 1000
    // outline: solid 1px #ccc
    // border-top: solid 1px #ccc
    box-shadow: inset 0px 0px 0px 1px rgba(0,0,0, 0.1), 0 0.3rem 0.5rem rgba(0, 0, 0, 0.05)
    // border-bottom: 0
    color: #333 !important
  tbody
    tr
      td
        color: #555
        &.text-pre
          white-space: pre-line
.block-html
  // opacity: 0.3
  // transition: opacity 0.2s
  // &:hover
  //   opacity: 1
.query-editor
  // background: #2d2d2d
  // color: #ccc
  background: #f5f2f0

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace
  // font-size: 14px
  line-height: 1.5
  padding: 1rem
  border-radius: 1rem

.query-result


</style>
<template lang="pug">
div
  .d-flex.justify-content-center.align-items-top(style='min-height: 100vh')
    .col-10
      .mb-4
      h3.title.py-3 디비 가져오기
      div.async(:class='{done: done}')
        form.form(@submit.prevent='submit()')
          .pb-4

          .form-group
            label.d-block 데이터베이스 선택
            .row
              .col
                select.form-control()
                  option MySQL 5.7.34 / 어드민디비 읽기전용 / id.c3wwmxxccs3.ap-northeast-2.rds.amazonaws.com
                  option MySQL 5.7.26 / 워드프레스 / wp-deployment.29.dblayer.com:24858/compose
              .col-lg-3.pl-0
                button.btn.btn-secondary.btn-block(type='button') 연결 추가하기
            //- span.text-muted 가져올 엑셀 내용을 복사, 붙여넣기 해주세요.

          .form-group.pt-4
            label.d-block SQL 쿼리 입력
            //- h3
              span.badge.badge-light.p-3 가져올 엑셀 내용을 복사, 붙여넣기 해주세요.
            prism-editor(class='query-editor' v-model='query' :highlight='highlighter' line-numbers)
            //- textarea.form-control(rows=5 v-model='import_data' @blur='blur_import_data' autofocus placeholder='여기에 붙여넣기')
            small.text-muted LIMIT, OFFSET, TOP이 없는 경우 자동으로 입력됩니다.

          .form-group.pt-4
            label.d-block 쿼리 결과
              button.btn.btn-light.text-primary.ml-2(type='button' @click='get_result') 새로고침

            table.table.table-bordered.query-result.shadow-sm(style='font-size: 12px')
              thead
                tr
                  th.bg-light(v-for='h in result_headers') {{h}}
              tbody
                tr(v-for='r in result')
                  td(v-for='h in result_headers') {{r[h]}}
            small.text-muted No errors, 3 rows, taking 12.4 ms

          .form-group.pt-4
            label.d-block 옵션 조절


            .bg-light.p-4
              label.text-secondary 페이지 나누기
              input.form-control(type='text' value='100')

              .mt-4
              label.text-secondary 필터링 검색 허용하기
              b-form-checkbox.rounded-0(v-model='form.use_search' value='Y' unchecked-value='N' switch) 쿼리 수정 권한이 없어도 검색필터를 통해 조건을 변경합니다.

              .mt-4
              label.text-secondary 캐시 적용
              select.form-control()
                option() 캐시 안함 (조회시 요청하여 항상 최신 표시)
                option() 15분 (15m)

              .mt-4
              label.text-secondary 로그 정책
              b-form-checkbox.rounded-0(v-model='form.use_access_log' value='Y' unchecked-value='N' switch) 조회한 사용자 기록을 남깁니다.
              b-form-checkbox.rounded-0(v-model='form.use_error_query_log' value='Y' unchecked-value='N' switch) 에러 쿼리 로그를 남깁니다.
              b-form-checkbox.rounded-0(v-model='form.use_slow_query_log' value='Y' unchecked-value='N' switch) 응답이 지연된 쿼리(slow query) 로그를 남깁니다.
              b-form-checkbox.rounded-0(v-model='form.use_full_query_log' value='Y' unchecked-value='N' switch) 모든 쿼리 로그를 남깁니다.

          .pb-4
          .form-group
            button.btn.btn-primary(type='submit' :disabled='saving || !prepared') {{saved_text}}
          .clearfix
          .pb-4

      .clearfix
      .mb-4

</template>

<script>

const Papa = require('papaparse')



import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism.css'; // import syntax highlighting styles
// import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

export default {
  name: 'index',
  props: ['property_id', 'document_id'],
  components: {
    PrismEditor,
    // VueExcelEditor,
  },
  computed: {
    parsed_headers_mapping_by_excel() {
      const a = {}
      for (const k of Object.keys(this.parsed_headers_mapping)) {
        a[this.parsed_headers_mapping[k]] = k
      }
      return a
    },
    // session() {
    //   return this.$store.state.session
    // },
    // property() {
    //   return this.$store.state.property
    // },
  },
  watch: {
    '$store.state.documents'() {
      this.load()
    }
  },
  data() {
    return {
      done: false,
      saving: false,
      prepared: false,
      saved_text: '저장하기',

      query: `SELECT * FROM reservations
WHERE created_at BETWEEN '2021-03-01' AND '2021-04-01'
ORDER BY id DESC`,
// LIMIT 100
      result_headers: [],
      result: [
        {
          id: 3,
          guest_name: '김손손 2명',
          store_name: '문정점',
          product_id: 2311,
          created_at: '2021-03-01 19:45:01',
        },
        {
          id: 2,
          guest_name: '박가람',
          store_name: '위례중앙로점',
          product_id: 3990,
          created_at: '2021-03-01 19:43:59',
        },
        {
          id: 1,
          guest_name: '이용우',
          store_name: '위례중앙로점',
          product_id: 3990,
          created_at: '2021-03-01 19:40:15',
        },
      ],

      prepare_text: '가져올 내용이 없습니다.',
      count_submit: 0,

      import_data: '',
      results: {
        data: [],
        errors: [],
        meta: {},
      },
      headers: [],

      current_page: 1,

      step: 1,

      document: {},
      error: '',

      drag: false,

      drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },

      form: {
        use_search: 'Y',
        use_error_query_log: 'Y',
        use_slow_query_log: 'Y',
        use_full_query_log: 'N',
        customer_id: 'Y',
        customer_name: 'Y',
        customer_phone: 'Y',
        customer_email: 'Y',
      },

      last_col_id: 10,

      cols: [

      ],

      import_cols: {},

      should_save_cols: false,
      apply_header: 'N',
      parsed_headers: [],
      warning_header: [],
      parsed_headers_mapping: {
        '@고객이름': undefined,
        '@고객연락처': undefined,
        '@고객이메일': undefined,
        '@고객아이디': undefined,
      },
      apply_upsert: 'N',

      parse_type: 'rows',
      // parse_type: 'horizontal_header_rows',
      show_parse_option: false,
      parsed_h_headers: [],
      parsed_h_headers_hint: [],

      paste_message: '',

      preview_editing: false,
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    // this.load()
    if (this.$store.state.documents) {
      this.load()
    }

    this.result_headers = Object.keys(this.result[0])
    setTimeout(() => {
    }, 300);
  },
  methods: {
    get_result() {

    },
    highlighter(code) {
      // return highlight(code, languages.js); // languages.<insert language> to return html with markup
      return highlight(code, languages.js)
    },
    blur_import_data() {
      if (this.import_data.length) {
        return this.preview()
      }
      this.step = 1
      this.prepared = false
      this.$modal.show('dialog', {
        title: '알림',
        text: '내용을 입력해주세요.',
      })
    },
    load() {
      this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      if (!this.document.id) {
        this.error = '해당 목록을 찾지 못했습니다.'
        return
      }

      if (!this.document.config) {
        this.document.config = {}
      }
      if (!this.document.config.cols) {
        this.document.config.cols = []
      }
      if (this.document.config.last_col_id) {
        this.last_col_id = this.document.config.last_col_id
      }
      const prev_cols = this.cols.slice()
      const prev_cols_by_key = {}
      for (const col of prev_cols) {
        prev_cols_by_key[col.key] = col
      }
      console.log('>>', prev_cols_by_key)
      this.cols = this.document.config.cols.slice()

      for (const col of this.cols) {
        const f = prev_cols_by_key[col.key]
        if (f) {
          col.value = f.value
        }
      }

      this.done = true
    },
    preview() {
      this.preview_editing = false

      if (this.parse_type == 'header_rows') {
        this.apply_header = 'Y'
      } else if (this.parse_type == 'horizontal_header_rows') {
        this.apply_header = 'Y'
      } else {
        this.apply_header = 'N'
      }

      const text = this.import_data.trim()
      if (!text || text.length === 0) {
        this.$modal.show('dialog', {
          title: '알림',
          text: ' 내용이 없습니다.',
        })
        this.step = 1
        return
      }

      let config = {}

      const r = Papa.parse(text, config)

      this.results = r
      if (this.results.errors.length) {
        this.prepare_text = `불러오기 실패`
        this.$modal.show('dialog', {
          title: '알림',
          text: this.prepare_text,
        })
        this.prepared = false
        this.step = 1
        return
      }
      if (this.results.data.length === 1) {
        this.prepare_text = `불러오기 실패`
        this.$modal.show('dialog', {
          title: '알림',
          text: '헤더와 내용, 최소 2줄 이상의 내용을 입력해주세요.',
        })
        this.prepared = false
        this.step = 1
        return
      }

      if (this.results && this.results.data) {
        this.parsed_headers = this.results.data[0]
        this.results.data = this.results.data.slice(1)
      }

      this.prepare_text = `${this.results.data.length}건의 데이터를 확인했습니다.`
      this.$modal.show('dialog', {
        title: '알림',
        text: this.prepare_text,
      })
      this.prepared = true


      this.step = 3
    },
    async submit() {
      try {
        this.saving = true

        this.saved_text = '저장 중'

        const new_cols = []

        for(const h of this.parsed_headers) {
          new_cols.push({
            key: 'd' + ++this.last_col_id,
            label: h,
            format: 'text',
            editable: true,
            position: 'secondary',
          })
        }

        const document = Object.assign({}, this.document, {
          config: Object.assign({}, this.document.config, {
            cols: new_cols,
            last_col_id: this.last_col_id,
            use_external_id: 'none',
          })
        })
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/documents/${this.document_id}`, document)
        if (r?.data?.message != 'ok') throw new Error('목록 항목 저장 실패 (고객데이터 추가전에 해당목록 항목을 먼저 저장이 필요합니다.)')

        if(!confirm(`${this.results.data.length}건을 추가할까요?`)) throw new Error('취소되었습니다.')

        for (const d of this.results.data) {
          const row = {}
          for (let i=0; i<d.length; i++) {
            // const label = this.parsed_headers[i]
            const col = new_cols[i]

            row[col.key] = String(d[i] || '')
          }
          const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records`, {
            row,
          }, {
            params: {
              apply_upsert: this.apply_upsert,
            }
          })
          if (r?.data?.message != 'ok') throw new Error(`고객 저장 실패 (${this.count_submit+1}/${this.results.data.length}) ` + r?.data?.message)
          this.count_submit = this.count_submit+1
        }
        this.saved_text = '저장됨'


        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.document_id}/${this.$options.filters.encodeText(this.document.name)}`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
        this.saved_text = '저장하기'
      }
      setTimeout(() => {
        this.saving = false
        this.$store.dispatch('documents', this.property_id)
      }, 300);
    },
  },
}
</script>
